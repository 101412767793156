import React, { Component } from 'react'
import {RestaurantBranchService} from "Components/services/RestaurantBranchService"

export class BottomMobile extends Component {
    constructor(props){
        super(props);
        this.$ = window.jQuery
        this._branchService = new RestaurantBranchService()
    }
    state = {

    }
    listBranches() {
        this._branchService.get()
        .then((res)=> {
            this.setState({branches: res.data.branches})
        }, (err)=> {
            console.error(err.response)
        })
    }
    openMobileDelivery = (bool) => {
        bool ? this.$('.mobile-deliverys').addClass('active') : this.$('.mobile-deliverys').removeClass('active') ;
    }
    fnMoveToScroll = (e) => {
        e.preventDefault();
        this.$('input[name=txtname]').trigger('focus')
    }
    componentDidMount(){
        this.listBranches()
    }
    showHomemovil(){
        const $ = window.jQuery
        let home = document.querySelector(".home-delivery")
        home.id == "true" ? hide() : show()
        
        function hide(){
            $(".home-delivery").removeClass("show-home")
            $(".home-delivery").addClass("hide")
            if($(".header").offset() < $(".section-promociones").offset()){
                $(".header").removeClass("fixed")
            }
            home.id = "false"
        }
        function show(){
            $(".home-delivery").removeClass("hide")
            $(".home-delivery").addClass("show-home")
            $(".header").addClass("fixed")
            home.id = "true"
        }
    }
    render() {
        return (
            <div>
                <div className="c-marron w-100 bg-amarillo p-fixed align-top mobile-deliverys" style={{overflow:'auto',maxHeight:'100vh', paddingTop:'2em',paddingBottom:'2em'}}>
                    <div className="w-90 m-auto">
                        <div className="text-center" onClick={(e) => this.openMobileDelivery(false)}>
                            <i className="fas fa-times fa-2x"></i>
                        </div>
                        <ul>
                        {this.state.branches ? this.state.branches.map((b, i)=>
                            <li className="d-flex justify-content-between" style={{marginTop:'3em'}}>
                                <div className="local d-flex flex-column">
                                    <span className="ProximaNovaBold">{b.name}</span>
                                    <span className="ProximaNovaThin">{b.address}<br/>{b.district}</span>
                                </div>
                                <a href={`tel:01${b.phone1}`} target="_blank" className="btn-phone c-marron">
                                    <i className="fas fa-phone-alt"></i>
                                </a>
                            </li>
                        ): null}
                        </ul>
                    </div>
                </div>
                <div className="navigation-bottom-fixed">
                    <a 
                        className="content-icon icon-carta text-center"
                        onClick={this.showHomemovil.bind(this)}
                    >
                        <div className="icon" style={{backgroundImage:`url(${require("Assets/images/header/delivery.svg")})`}}/>
                        <span className="ProximaNovaBold">Ordena aquí</span>
                    </a>
                    <a 
                        onClick={() => this.openMobileDelivery(true)}
                        className="content-icon icon-delivery text-center" 
                    >
                        <div className="icon" style={{backgroundImage:`url(${require("Assets/images/header/phone.svg")})`}} />
                        <span className="ProximaNovaBold">Delivery</span>
                    </a>
                    {/* <a 
                        onClick={(e) => this.fnMoveToScroll(e)}
                        className="content-icon icon-reservas text-center"  
                    >
                        <div className="icon" style={{backgroundImage:`url(${require("Assets/images/header/reservas.svg")})`}} />
                        <span className="ProximaNovaBold">Reservas</span>
                    </a> */}
                </div>
            </div>
        )
    }
}
