import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="text-center text-white bg-vino GothamBook">
                Píkalo &copy;2019 Created with <i className="far fa-heart text-white" /> <a href="https://www.formulaperu.com/" className="text-white" target="_blank">by Fórmula.</a>
            </footer>
        )
    }
}
