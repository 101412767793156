import React, { Component } from 'react'
import axios from "axios"
export class Menu extends Component {
    constructor(){
        super()
        document.title = "Carta"
    }
    handleSubmit(e){
        e.preventDefault()
        const submit_button = document.querySelector("[type=submit]")
        submit_button.innerHTML = "Guardando ..."
        
        axios.put("http://pikalo.formulaperu.com/api/menu", new FormData(e.target))
        .then((resp)=>{
            submit_button.innerHTML = '<i className="fas fa-save"></i> Guardar'
            alert("Carta actualizada")
            window.location.reload(true)
            console.log(resp.data)
        },(err)=>{
            submit_button.innerHTML = '<i className="fas fa-save"></i> Guardar'
            console.error(err.response)
        })
    }
    render() {
        return (
            <div className="page-carta m-auto">
                <div className="row">
                    <div className="col-sm-3">
                        <form className="card mt-2" onSubmit={this.handleSubmit}>
                            <div className="card-header">
                                <i className="fas fa-edit"></i>Actualiza la carta
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <input type="hidden" name="id" className="form-control" required />
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="control-label">Archivo</label>
                                                <input type="file" name="menu" accept="application/pdf" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary btn-sm"><i className="fas fa-save"></i> Guardar</button>
                            </div>
                        </form>
                    </div>
                    <embed src="http://pikalo.formulaperu.com/uploads/carta-pikalo.pdf" className="col-sm-8" width="500" height="1000px"/>
                </div>
            </div>
        )
    }
}
