import React, { Component } from 'react'

export default class Loading extends Component {
    constructor(props){
        super(props);
        this.$ = window.jQuery
        this.state = {
          loading : 'active'
        }
    }

    componentDidMount = () => {
        this.$('html').css({ 'overflow':'hidden' });
        setTimeout(() => this.removeLoading(), 2500);
    }

    removeLoading = () => {
        this.setState({ loading : '' })
        this.$('html').css({ 'overflow':'auto' })
    }

    render() {
        return (
            <div className={"section-loading "+this.state.loading} id="myLoading">
                <div 
                    className="o-complete animated pulse infinite" 
                    style={{WebkitMaskImage:`url(${require("Assets/images/o-complete.svg")})`}} 
                />
            </div>
        )
    }
}
