import React, { Component } from 'react'
import {Card} from "./components/Card"

export class Dashboard extends Component {
    componentDidMount() {
        document.title = "Dashboard - Administrador Pikalo"
    }
    render() {
        return (
            <main className="container headerfobic">
                <div className="jumbotron">
                    <h1 className="display-4">Dashboard</h1>
                    <p className="lead">Selecciona uno de los bloques para comenzar a gestionar tu sitio.</p>
                    <hr className="my-4" />
                    <div className="row">
                        <Card title="Promociones" desc="Crea, edita y elimina promociones" href="/admin/promociones" color="primary" btnType="warning" btnText="Administrar" />
                        <Card title="Sucursales" desc="Edita datos de las sucursales" href="/admin/sucursales" color="secondary"  btnType="dark" btnText="Administrar" />
                        <Card title="Carta" desc="Actualiza la carta" href="/admin/carta" color="dark" btnType="light" btnText="Administrar" />
                    </div>
                </div>
            </main>
        )
    }
}