import React, { Component } from 'react'
import {BrowserRouter as Router, Route, Link, Redirect, withRouter} from 'react-router-dom'

const fakeAuth = {
    isAuthenticated: localStorage.getItem('loggedIn') === "true",
    authenticate(cb) {
        this.isAuthenticated = true
        setTimeout(cb, 100)
    },
    signout(cb) {
        this.isAuthenticated = false
        setTimeout(cb, 100)
    }
}

const Public = () => <h3>Public</h3>
const Protected = () => <h3>Protected</h3>

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      fakeAuth.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
            pathname: '/admin/login',
            state: { from: props.location }
          }} />
    )} />
)

const AuthButton = withRouter(({ history }) => (
    fakeAuth.isAuthenticated ? (
      <p>
        Welcome! <button onClick={() => {
          fakeAuth.signout(() => history.push('/'))
        }}>Sign out</button>
      </p>
    ) : (
      <p>You are not logged in.</p>
    )
))

export class Login extends Component {
    state = {
        error: true,
        redirectToReferrer: false
    }
    login = (e) => {
        e.preventDefault()

        const form = e.target

        if (!(form.username.value === 'admin' && form.password.value === 'dg54<2456@')) {
            alert("Usuario o contraseña invalida")
            return this.setState({ error: true });
        }

        localStorage.setItem('loggedIn', true);

        fakeAuth.authenticate(() => {
            this.setState(() => ({
                redirectToReferrer: true
            }))
        })
    }
    render() {
        const { from } = this.props.location.state || { from: { pathname: '/admin' } }
        const { redirectToReferrer } = this.state
    
        if (redirectToReferrer === true) {
          return <Redirect to={from} />
        }
        return (
            <div className="">
                <div className="text-center" style={{width: '100vw', height: '100vh', display: 'flex'}}>
                    <form className="form-signin" onSubmit={(e)=> this.login(e)}>
                        <h1 className="h3 mb-3 font-weight-normal">Iniciar Sesión</h1>
                        <input type="text" name="username" className="form-control mb-2" placeholder="Ingrese usuario" required autofocus/>
                        <input type="password" name="password" className="form-control mb-2" placeholder="Ingrese contraseña" required/>
                        <button className="btn btn-lg btn-primary btn-block" type="submit" >Ingresar</button>
                        <p className="mt-5 mb-3 text-muted">&copy; <a href="http://pikalo.com.pe">Pikalo</a> - 2020</p>
                    </form>
                </div>
            </div>
        )
    }
}
