import React, { Component } from 'react'

export default class ImagePromotion extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div className={"box-image barprogress-"+this.props.barprogress}>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width: this.props.progress+"%"}} aria-valuenow={this.props.progress} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img data-id={this.props.image.id} data-type="promotion" className="img img-fluid" src={`http://pikalo.formulaperu.com/uploads/${this.props.image.image}`} />
                    </div>
                </div>
                <div className="closeButton" onClick={() => this.props.delete(this.props.image)}>
                    <i className="fas fa-times"></i>
                </div>
            </div>
        )
    }
}
