import React, { Component } from 'react'

export class WebLayout extends Component {
    componentWillMount = () =>{
        document.title = this.props.title
    }
    render() {
        return (
            <div className={this.props.classPage}>
                {
                    this.props.children
                }
            </div>
        )
    }
}
