import React, { Component } from 'react'

export class Card extends Component {
    render() {
        return (
            <div className="col-12 col-md-6">
                <div class={`card text-white bg-${this.props.color} mb-3`}>
                    <div className="card-body">
                        <h5 className="card-title">{this.props.title}</h5>
                        <p className="card-text">{this.props.desc}</p>
                        <div className="text-right">
                            <a href={this.props.href} class={`btn btn-${this.props.btnType}`}>{this.props.btnText}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}