import React, { Component } from 'react'

export class AdminHeader extends Component {
    handleLogOut() {
        localStorage.removeItem('loggedIn');
        window.location = "/admin/login"
    }
    render() {
        return (
            <header className="header-admin fixed-top d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
                <h5 className="my-0 mr-md-auto font-weight-normal"><a href="/admin">Píkalo &#8211; Administrador</a></h5>
                <nav className="my-2 my-md-0 mr-md-3">
                    <a className="p-2 text-dark" href="/admin/sucursales">Sucursales</a>
                    <a className="p-2 text-dark" href="/admin/promociones">Promociones</a>
                    <a className="p-2 text-dark" href="/admin/carta">Carta</a>
                </nav>
                <a className="btn btn-outline-danger" href="#" onClick={this.handleLogOut}>Cerrar sesión</a>
            </header>
        )
    }
}