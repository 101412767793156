import axios from "axios"

export class ImageService {
    constructor() {
        this._url = "http://pikalo.formulaperu.com/api/"
        // this._url = "http://localhost:3001/"
    }

    create(image){
        return axios.post(`${this._url}promotions`, image)
    }
    get(image){
        return axios.get(`${this._url}promotions`)
    }
    delete(image){
        return axios.delete(`${this._url}promotions/${image.id}`)
    }
    updatePriority(id, priority){
        return axios.put(`${this._url}promotions/${id}`, {
            priority: priority
        })
    }
}