import React, { Component } from 'react'
import Sortable from 'sortablejs';
import { sucursalesData } from "Views/public/resources/sucursales"
import {RestaurantBranchService} from "Components/services/RestaurantBranchService"
export class RestaurantBranch extends Component {
    constructor(){
        super()
        this._branchService = new RestaurantBranchService()
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
        }
    }
    listBranches(){
        this._branchService.get()
        .then((res)=> {
            this.setState({branches: res.data.branches})
        }, (err)=> {
            console.error(err.response)
        })
    }
    setCurrentBranch(branch){
        this.currBranch = branch
        document.querySelectorAll("form input").forEach((el)=>{
            el.value = branch[`${el.name}`]
        })
    }
    handleSubmit(e){
        e.preventDefault()
        const form = e.target
        const branchData = {
            "name": form.name.value,
            "phone1": form.phone1.value,
            "phone2": form.phone2.value,
            "address": form.address.value,
            "district": form.district.value,
            "coordinates": form.coordinates.value
        }

        if (form.id.value) {
            console.log(branchData)
            this._branchService.update(this.currBranch, branchData).then((res)=> {
                // this.setState(({branches})=> branches.find(b.id = this.currBranch.id))
                alert("Datos actualizados")
            }, (err)=> {
                alert("Error!")
                console.error(err.response)
            })
        } else {
            this._branchService.create(branchData).then((res)=> {
                this.setCurrentBranch(res.data.branch)
                this.setState(({branches}) => branches.push(res.data.branch))
                alert("Sucursal creada")
            }, (err)=> {
                alert("Error!")
                console.error(err.response)
            })
        }
        
    }
    componentDidMount(){
        this.listBranches()
    }
    render() {
        return (
            <div className="section section-restaurantBranch">
                <div className="w-90 m-auto">
                    <div className="row">
                        <div className="col-md-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/admin/">Inicio</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Sucursales</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card mt-2">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Surcursales
                                    {/* <button className="btn btn-success btn-sm" style={{borderRadius:'50%'}}><i className="fas fa-plus"></i></button> */}
                                </div>
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush">
                                    {this.state.branches ?
                                        this.state.branches.map((b, index) =>
                                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center cursor-pointer">
                                                <a href="javascript:;" onClick={(e)=>this.setCurrentBranch(b)}>
                                                    <div>
                                                        <b>{b.name}</b>
                                                    </div>
                                                </a>
                                                {/* <div className="d-flex justify-content-between align-items-center">
                                                    <span className="badge badge-success badge-pill">
                                                        <i className="fas fa-phone"></i>
                                                    </span>
                                                    <span className="badge badge-primary badge-pill ml-2">
                                                        <i className="fas fa-map-marker-alt"></i>
                                                    </span>
                                                </div> */}
                                                {/* <button className="btn btn-danger"><i className="fas fa-trash"></i></button> */}
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <form className="card mt-2" onSubmit={this.handleSubmit}>
                                <div className="card-header">
                                    <i className="fas fa-edit"></i> Administración
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <input type="hidden" name="id" className="form-control" required />
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="control-label">Nombre</label>
                                                <input type="text" name="name" className="form-control" required />
                                            </div>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="control-label">Teléfono</label>
                                                <input type="tel" name="phone1" className="form-control" required />
                                            </div>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="control-label">Teléfono 2 (opcional)</label>
                                                <input type="tel" name="phone2" className="form-control" />
                                            </div>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="control-label">Dirección linea 1</label>
                                                <input type="text" name="address" className="form-control" required />
                                            </div>
                                            <div className="form-group col-md-6 mb-3">
                                                <label className="control-label">Dirección linea 2</label>
                                                <input type="text" name="district" className="form-control" required />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="control-label">Coordenadas</label>
                                                    <input type="text" name="coordinates" className="form-control" required />
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary btn-sm"> <i className="fas fa-save"></i> Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
