export const sucursalesData = [
    {
        'id':'mayolo',
        'sucursal':'Píkalo Mayolo',
        'direccion':'Av.Antúnez de Mayolo 1396',
        'distrito':'Los Olivos',
        'telefonoText':"714-1510 - 714-1511",
        'telefonoNum':"7141510",
        'latitud':'-11.9953896',
        'centerLatitud':'-11.9948896',
        'longitud':'-77.0799809'
    },
    {
        'id':'palmeras',
        'sucursal':'Píkalo Palmeras',
        'direccion':'Av. Carlos Izaguirre 920',
        'distrito':'Los Olivos',
        'telefonoText':"717-5390 - 523-1010",
        'telefonoNum':"7175390",
        'latitud':'-11.9909868',
        'centerLatitud':'-11.9904868',
        'longitud':'-77.0721871'
    },
    {
        'id':'canada',
        'sucursal':'Píkalo Canadá',
        'direccion':'Av.Canadá 3420',
        'distrito':'San Borja',
        'telefonoText':"719-1740 - 719-1741",
        'telefonoNum':"7191740",
        'latitud':'-12.0815363',
        'centerLatitud':'-12.0810363',
        'longitud':'-76.9945903'
    },
    {
        'id':'peru34',
        'sucursal':'Píkalo Av. Perú 34',
        'direccion':'Av. Perú 3482',
        'distrito':'San Martin de Porres',
        'telefonoText':"717-5393 - 568-6578",
        'telefonoNum':"7175393",
        'latitud':'-12.030723',
        'centerLatitud':'-12.030023',
        'longitud':'-77.0852172'
    },
    {
        'id':'huaral',
        'sucursal':'Píkalo Huaral',
        'direccion':'Av.Chancay N°1248',
        'distrito':'Int. R 01 KM.8. MegaPlaza Huaral',
        'telefonoText':"223-3213",
        'telefonoNum':"2233213",
        'latitud':'-11.502269',
        'centerLatitud':'-11.501269',
        'longitud':'-77.225222'
    },
    {
        'id':'royal',
        'sucursal':'Píkalo Royal',
        'direccion':'Calle los Andes MZ B LT 01',
        'distrito':'Independencia',
        'telefonoText':"717-5388 - 521-0068",
        'telefonoNum':"7175388",
        'latitud':'-11.991416',
        'centerLatitud':'-11.990516',
        'longitud':'-77.0629314'
    },
    {
        'id':'peru17',
        'sucursal':'Píkalo Av. Perú 17',
        'direccion':'Av. Perú 1741',
        'distrito':'San Martin de Porres',
        'telefonoText':"717-5395 - 571-5309",
        'telefonoNum':"7175395",
        'latitud':'-12.0324634',
        'centerLatitud':'-12.0320334',
        'longitud':'-77.0641512'
    },
    {
        'id':'surco',
        'sucursal':'Píkalo Surco',
        'direccion':'Jr. Doña Rosa N° 134',
        'distrito':'URB. Los Rosales STGO. Surco',
        'telefonoText':"715-5868 - 715-5869",
        'telefonoNum':"7155868",
        'latitud':'-12.1402162',
        'centerLatitud':'-12.139262',
        'longitud':'-76.9989473'
    },
]