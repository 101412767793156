import axios from "axios"

export class RestaurantBranchService {
    constructor() {
        this._url = "http://pikalo.formulaperu.com/api/"
        // this._url = "http://127.0.0.1:3001/"
    }

    create(branch){
        return axios.post(`${this._url}branches`, branch)
    }
    get(){
        return axios.get(`${this._url}branches`)
    }
    delete(branch){
        return axios.delete(`${this._url}branches/${branch.id}`)
    }
    update(branch, branchData){
        return axios.put(`${this._url}branches/${branch.id}`, branchData)
    }
}