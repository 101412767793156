import React, { Component } from 'react'
import ImagePromotion from "Views/admin/components/Promotions/ImagePromotion"
import {ImageService} from "Components/services/ImageService"
import { UncontrolledTooltip } from 'reactstrap';
import Sortable from 'sortablejs';
import axios from "axios"

export class Promotions extends Component {
    constructor() {
        super()
        this._imageService = new ImageService()
        this.state = {
            view: false
        }
        this.handleDelete = this.handleDelete.bind(this)
        this.updatePriority = this.updatePriority.bind(this)

    }

    handleDelete(image) {
        this._imageService.delete(image)
        .then((resp)=>{
            this.setState({images: resp.data.data})
        },({err})=>{
            console.log(err)
        })
    }

    async handleFile(e) {
        const files = e.target.files
        console.log(files)
        for (let i = 0; i < files.length; i++) {
            await this.uploadFile(files[i])
        }
        await this.updatePriority()
        window.location.reload()
    }

    async uploadFile(file) {
        const payload = new FormData()
        payload.append('image', file)

        this._imageService.create(payload)
        .then((result)=>{
            this.setState(({images}) => images.unshift(result.data.promotion))
            console.log(result)
        }, ({response})=>{
            console.log(response)
        })
    }

    async updatePriority() {
        const images = document.querySelectorAll('[data-type="promotion"]')
        let promises = []
        for(const [i, img] of Object.entries(images)){
             promises.push(this._imageService.updatePriority(img.dataset.id, i))
        }
        return await axios.all(promises).then(r=>{
            alert("Datos actualizados")
            console.log(r)
        }).catch(e=>{
            alert("Error")
            console.error(e)
        })
    }

    componentDidMount = () => {
        this._imageService.get()
        .then((resp)=>{
            this.setState({images: resp.data.data.sort((a, b) => a.priority - b.priority), view: true})
            // this.setState({images: resp.data.data, view: true})
            console.log(this.state.images)
            var el = document.getElementById('box-images')
            var sortable = Sortable.create(el, {
                animation: 150,
                ghostClass: 'blue-background-class'
            })
        },({err})=>{
            console.log(err)
        })
    }
    render() {
        if (this.state.view) {
            return (
                <div className="section section-promotions">
                    <div className="w-90 m-auto">
                        <nav aria-label="breadcrumb" style={{width:400}}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Inicio</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Promociones</li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-header">
                                        Formulario
                                    </div>
                                    <div className="card-body">
                                        <div className="float-right" id="question-function">
                                            <i className="fas fa-question-circle"></i>
                                        </div>
                                        <div className="form-group">
                                            <input type="file" name="file" accept="image/png, image/jpeg" multiple onChange={(e)=> this.handleFile(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-header">
                                        Promociones
                                    </div>
                                    <div className="card-body">
                                        <div className="box box-images d-flex justify-content-left" id="box-images" style={{flexWrap:'wrap'}}>
                                            {this.state.images.map((img, i)=>
                                                <ImagePromotion 
                                                    image={img} 
                                                    barprogress={false} 
                                                    progress={0}
                                                    delete={this.handleDelete}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between align-items-center">
                                            <div>Total de imagenes: {this.state.images.length}</div>
                                        <div>
                                            <button type="button" className="btn btn-primary btn-sm" onClick={this.updatePriority}><i className="fas fa-save" /> Guardar orden</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <UncontrolledTooltip placement="top" target="question-function">
                        Al seleccionar las imágenes se subira inmediatamente al servidor
                    </UncontrolledTooltip>
                </div>
            )
        } else {
            return (<div></div>)
        }
        
    }
}
