import React, {Component, Suspense} from 'react'
import { BrowserRouter as Router, Route, Link, Redirect, withRouter, Switch} from "react-router-dom";
import ScrollToTop from "Components/Others/ScrollToTop"
import Loading from "Components/Others/Loading"
import { Inicio, Encuesta } from "Views/public"
import { Header, Footer, BottomMobile, HeaderEncuesta } from "Layout"
import { Dashboard, RestaurantBranch, Promotions, Menu } from "Views/admin"
import { AdminHeader } from "Views/admin/components"
import { Login, PrivateRoute } from "Views/admin/Login"
console.log(localStorage.getItem('loggedIn'))
export default class ApplicationRoutes extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <div id="main-content">
                        <Suspense fallback={<h1></h1>}>
                        <Switch>
                            <Route exact path="/encuesta">
                                <div className="page-encuesta">
                                    <HeaderEncuesta mainClass={"fixed"}/>
                                    <Route path="/encuesta" exact component={Encuesta} />
                                    <Footer />
                                </div>
                            </Route>
                            <Route path="/admin/login" exact component={Login} />
                            <Route path="/admin">
                                <div className="page-admin">
                                    <AdminHeader/>
                                    <PrivateRoute path="/admin" exact component={Dashboard} />
                                    <PrivateRoute path="/admin/promociones" exact component={Promotions} />
                                    <PrivateRoute path="/admin/sucursales" exact component={RestaurantBranch} />
                                    <PrivateRoute path="/admin/carta" exact component={Menu} />
                                </div>
                            </Route>
                            <Route exact path="/login">
                                <div className="page-login">
                                    <Route path="/login" exact component={Login} />
                                </div>
                            </Route>
                            <Route exact path="/">
                                <div className="page-home">
                                    <Loading />
                                    <Header />
                                    <Route path="/" exact component={Inicio} />
                                    <Footer />
                                    <BottomMobile />
                                </div>
                            </Route>
                            <Route component={()=><Redirect to="/" />}/>
                        </Switch>
                        </Suspense>
                    </div>
                </ScrollToTop>
            </Router>
        )
    }
}
