import React, { Component } from 'react'
import MediaQuery from 'react-responsive';
import { HashLink as Link } from 'react-router-hash-link';

export class HeaderEncuesta extends Component {
    constructor(props){
        super(props);
        this.state = {
            menuActive: ''
        }
    }

    fnMenuOpen = () => {
        const $ = window.jQuery
        var option = ''
        if ($('html.blocked').length > 0) {
            option = ''
            $('html').removeClass('blocked')
        }else{
            option = 'active'
            $('html').addClass('blocked')
        }
        this.setState({ menuActive:option })
    }

    fnSubmenu = () => {
        const $ = window.jQuery
        $('.mySubmenu.open').length > 0 ? $('.mySubmenu').removeClass('open') : $('.mySubmenu').addClass('open');
    }

    fnSetUbicacionActive = (e, option) =>{
        const $ = window.jQuery
        e.preventDefault();
        var position = Number($('#ubicacion').offset().top);
        $("html, body").animate({scrollTop:position}, 500, 'swing');
        $('.content-ubicaciones .attr-ubicacion.'+option).click()
    }

    componentDidMount = () =>{
        const $ = window.jQuery
        $('.prevented a:link').click(function(e){
            e.preventDefault();
        })
        $('.dropdown-toggle').click(function(e){
            e.preventDefault()
            $(this).focus().closest('.dropdown').addClass('show').find('.dropdown-menu').addClass('show')
        })
        $('.dropdown-toggle').blur(function(e){
            e.preventDefault()
            let thes = this;
            setTimeout(function(){
                $(thes).closest('.dropdown').removeClass('show').find('.dropdown-menu').removeClass('show')
            },300)
        })
    }

    render() {
        return (
            <header className={"header "+this.props.mainClass}>
                <div className="w-85 m-auto d-flex justify-content-between align-items-center content-header">
                    <a href="/" className="logo">
                        <img src={require("Assets/images/logo-white.png")} className="img img-fluid"/>
                        <img src={require("Assets/images/logo.png")} className="img img-fluid"/>
                    </a>
                    <div className="d-flex">
                        <MediaQuery query="(min-width:769px)">
                        <div className="menu">
                            <ul className="d-flex">
                                <li className="m-3">
                                    <a href="/#home">
                                        Inicio
                                    </a>
                                </li>
                                <li className="m-3">
                                    <a href="/#promociones">
                                        Promos
                                    </a>
                                </li>
                                <li className="m-3">
                                    <a href="/#ubicacion">
                                        Locales
                                    </a>
                                </li>
                                <li className="m-3">
                                    <a href="/#contacto">
                                        Reservas
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="others-option">
                            <ul className="d-flex">
                                <li className="m-2">
                                    <a href="http://pikalo.formulaperu.com/uploads/carta-pikalo.pdf" target="_blank" className="btn btn-rounded btn-light bg-lightgray d-flex justify-content-center align-items-center pl-3 pr-3">
                                        <div className="icon icon-carta" style={{backgroundImage:`url(${require("Assets/images/header/delivery.svg")})`}} /> Ver la carta
                                    </a>
                                </li>
                                <li className="m-2">
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-rounded d-flex justify-content-center align-items-center bg-amarillo c-marron pl-3 pr-5 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="icon icon-delivery" style={{backgroundImage:`url(${require("Assets/images/header/carta.svg")})`}} /> Delivery
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a href="/" className="dropdown-item text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <span className="ProximaNovaBold">Píkalo Mayolo</span>
                                                    <span className="ProximaNovaThin"><i className="fas fa-phone-alt" /> 714-1510 / 714-1511</span>
                                                </div>
                                            </a>
                                            <a href="/" className="dropdown-item text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <span className="ProximaNovaBold">Píkalo Canadá</span>
                                                    <span className="ProximaNovaThin"><i className="fas fa-phone-alt" /> 719-1740 / 719-1741</span>
                                                </div>
                                            </a>
                                            <a href="/" className="dropdown-item text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <span className="ProximaNovaBold">Píkalo Huaral</span>
                                                    <span className="ProximaNovaThin"><i className="fas fa-phone-alt" /> 223 3213</span>
                                                </div>
                                            </a>
                                            <a href="/" className="dropdown-item text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <span className="ProximaNovaBold">Píkalo Av. Perú 17</span>
                                                    <span className="ProximaNovaThin"><i className="fas fa-phone-alt" /> 717-5395 / 571-5309</span>
                                                </div>
                                            </a>
                                            <a href="/" className="dropdown-item text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <span className="ProximaNovaBold">Píkalo Palmeras</span>
                                                    <span className="ProximaNovaThin"><i className="fas fa-phone-alt" /> 717-5390 / 523-1010</span>
                                                </div>
                                            </a>
                                            <a href="/" className="dropdown-item text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <span className="ProximaNovaBold">Píkalo Av. Perú 34</span>
                                                    <span className="ProximaNovaThin"><i className="fas fa-phone-alt" /> 717-5393 / 568-6578</span>
                                                </div>
                                            </a>
                                            <a href="/" className="dropdown-item text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <span className="ProximaNovaBold">Píkalo Royal</span>
                                                    <span className="ProximaNovaThin"><i className="fas fa-phone-alt" /> 717-5388 / 521-0068</span>
                                                </div>
                                            </a>
                                            <a href="/" className="dropdown-item text-center">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <span className="ProximaNovaBold">Píkalo Surco</span>
                                                    <span className="ProximaNovaThin"><i className="fas fa-phone-alt" /> 715-5868 / 715-5869</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        </MediaQuery>
                        <div className="menuResponsive">
                            <div className={"d-flex button "+this.state.menuActive}>
                                <div id="btnButtonResponsive" className={"menu-button "+this.state.menuActive}  onClick={this.fnMenuOpen}/>
                                <div className="content-drawer">
                                    <div className="menuLinks">
                                        <div className="cd-menu-links-movil" style={{marginTop:'7vw'}}>
                                            {/*------*/}
                                            <ul className="d-flex flex-column">
                                                <li>
                                                    <a href="/#home" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Inicio
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/#promociones" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Promos
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/#ubicacion" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Locales
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/#contacto" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Reservas
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href="#" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Trabaja con Nosotros
                                                    </a>
                                                </li> */}
                                            </ul>
                                            {/*------*/}
                                        </div>
                                        <div className="cd-menu-social d-flex flex-column align-items-center c-marron" style={{marginBottom:'4vw'}}>
                                            <div className="text-center">
                                                Siguenos en
                                                <ul className="d-flex">
                                                    <li>
                                                        <a href="https://www.facebook.com/pikalo/" target="_blank">
                                                            <i className="c-marron fab fa-facebook-square"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.instagram.com/pikalo_oficial/?hl=es-la" target="_blank">
                                                            <i className="c-marron fab fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <label 
                                    htmlFor="btnButtonResponsive" 
                                    className="backdrop" 
                                    onClick={this.fnMenuOpen} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
