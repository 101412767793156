import React, { Component } from 'react'
import ScrollspyNav from "Components/Others/ScrollspyNav";
import MediaQuery from 'react-responsive';
import {RestaurantBranchService} from "Components/services/RestaurantBranchService"

export class Header extends Component {
    constructor(props){
        super(props);
        this._branchService = new RestaurantBranchService()
        this.state = {
            menuActive: '',
        }
    }

    listBranches() {
        this._branchService.get()
        .then((res)=> {
            this.setState({branches: res.data.branches})
        }, (err)=> {
            console.error(err.response)
        })
    }
    fnMenuOpen = () => {
        const $ = window.jQuery
        var option = ''
        if ($('html.blocked').length > 0) {
            option = ''
            $('html').removeClass('blocked')
        }else{
            option = 'active'
            $('html').addClass('blocked')
        }
        this.setState({ menuActive:option })
    }

    fnSubmenu = () => {
        const $ = window.jQuery
        $('.mySubmenu.open').length > 0 ? $('.mySubmenu').removeClass('open') : $('.mySubmenu').addClass('open');
    }

    fnSetUbicacionActive = (e, option) =>{
        const $ = window.jQuery
        e.preventDefault();
        var position = Number($('#ubicacion').offset().top);
        console.log(position)
        $("html, body").animate({scrollTop:position}, 500, 'swing');
        $('.content-ubicaciones .attr-ubicacion.'+option).click()
    }

    componentDidMount = () =>{
        this.listBranches()
        const $ = window.jQuery
       
        $('.prevented a:link').click(function(e){
            e.preventDefault();
        })
        $('.dropdown-toggle').click(function(e){
            e.preventDefault()
            const dropdown_menu = $(this).focus().closest('.dropdown')
            dropdown_menu.toggleClass("show")
            dropdown_menu.find('.dropdown-menu').toggleClass("show")
        })
        $('.dropdown-toggle').blur(function(e){
            e.preventDefault()
            let thes = this;
            setTimeout(function(){
                $(thes).closest('.dropdown').removeClass('show').find('.dropdown-menu').removeClass('show')
            },300)
        })
    }
    showHome(){
        const $ = window.jQuery
        let home = document.querySelector(".home-delivery")
        home.id == "true" ? hide() : show()
        
        function hide(){
            $(".home-delivery").removeClass("show-home")
            $(".home-delivery").addClass("hide")
            if($(".header").offset() < $(".section-promociones").offset()){
                $(".header").removeClass("fixed")
            }
            home.id = "false"
        }
        function show(){
            $(".home-delivery").removeClass("hide")
            $(".home-delivery").addClass("show-home")
            $(".header").addClass("fixed")
            home.id = "true"
        }
    }

    render() {
        return (
            <header className={"header "+this.props.mainClass}>
                <div className="w-85 m-auto d-flex justify-content-between align-items-center content-header">
                    <a href="/" className="logo">
                        <img src={require("Assets/images/logo-white.png")} className="img img-fluid"/>
                        <img src={require("Assets/images/logo.png")} className="img img-fluid"/>
                    </a>
                    <div className="d-flex">
                        <MediaQuery query="(min-width:769px)">
                        <div className="menu">
                            <ScrollspyNav
                                scrollTargetIds={ ['home','promociones', 'ubicacion'] } 
                                className="d-flex"
                                activeNavClass="active"
                            >
                                <li className="m-3">
                                    <a href="#home" className="active">
                                        Inicio
                                    </a>
                                </li>
                                <li className="m-3">
                                    <a href="#promociones">
                                        Promos
                                    </a>
                                </li>
                                <li className="m-3">
                                    <a href="#ubicacion">
                                        Locales
                                    </a>
                                </li>
                            {/* <li className="m-3">
                                    <a href="#">
                                    Trabaja con Nosotros
                                    </a>
                                </li> */}
                            </ScrollspyNav>
                                <li className="m-3">
                                    <a id="link-carta" target="_blank" href="http://pikalo.formulaperu.com/uploads/carta-pikalo.pdf">
                                        Ver la carta
                                    </a>
                                </li>
                        </div>
                        <div className="others-option">
                            <ul className="d-flex">
                                <li className="m-2">
                                    <a onClick={this.showHome.bind(this)}className="btn btn-rounded btn-light bg-amarillo d-flex justify-content-center align-items-center pl-3 pr-3">
                                        <div className="icon icon-carta" style={{backgroundImage:`url(${require("Assets/images/header/delivery.svg")})`}} /> Ordena aquí
                                    </a>
                                </li>
                                <li className="m-2">
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-rounded d-flex justify-content-center align-items-center  bg-lightgray c-marron pl-3 pr-5 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="icon icon-delivery" style={{backgroundImage:`url(${require("Assets/images/header/phone.svg")})`}} /> Delivery
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            {this.state.branches ? this.state.branches.map((b, i)=>                                            
                                                <a key={i} className="dropdown-item text-center" href="#" onClick={(e) => this.fnSetUbicacionActive(e,`pikalo-${b.id}`)}>
                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                        <span className="ProximaNovaBold">{b.name}</span>
                                                        <span className="ProximaNovaThin"><i className="fas fa-phone-alt" />{b.phone1 + " / " + b.phone2}</span>
                                                    </div>
                                                </a>
                                            ) : null}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        </MediaQuery>
                        <div className="menuResponsive">
                            <div className={"d-flex button "+this.state.menuActive}>
                                <div id="btnButtonResponsive" className={"menu-button "+this.state.menuActive}  onClick={this.fnMenuOpen}/>
                                <div className="content-drawer">
                                    <div className="menuLinks">
                                        <div className="cd-menu-links-movil" style={{marginTop:'7vw'}}>
                                            {/*------*/}
                                            <ScrollspyNav
                                                scrollTargetIds={ ['home','promociones', 'ubicacion'] }
                                                className="d-flex"
                                                activeNavClass="active"
                                            >
                                                <li>
                                                    <a href="#home" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Inicio
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#promociones" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Promos
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#ubicacion" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Locales
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href="#" onClick={(e) => this.fnMenuOpen(e)}>
                                                    Trabaja con Nosotros
                                                    </a>
                                                </li> */}
                                            </ScrollspyNav>
                                                <li>
                                                    <a id="click"  target="_blank" href="http://pikalo.formulaperu.com/uploads/carta-pikalo.pdf" onClick={(e) => this.fnMenuOpen(e)}>
                                                        Ver la carta
                                                    </a>
                                                </li>
                                            {/*------*/}
                                        </div>
                                        <div className="cd-menu-social d-flex flex-column align-items-center c-marron" style={{marginBottom:'4vw'}}>
                                            <div className="text-center">
                                                Siguenos en
                                                <ul className="d-flex justify-content-center">
                                                    <li>
                                                        <a href="https://www.facebook.com/pikalo/" target="_blank">
                                                            <i className="c-marron fab fa-facebook-square"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.instagram.com/pikalo_oficial/?hl=es-la" target="_blank">
                                                            <i className="c-marron fab fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="#">
                                                            <i className="c-marron fab fa-youtube"></i>
                                                        </a>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <label
                                    htmlFor="btnButtonResponsive"
                                    className="backdrop"
                                    onClick={this.fnMenuOpen}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
